<template>
  <div>
    <div class="page-header custom-container">
      <img class="img-title" src="@/assets/header_edit.svg" alt />
      <span>修改帳戶</span>
    </div>
    <div class="member custom-container-xl">
      <div class="member__box">
        <div class="member__photo">
          <div class="photo__inset">
            <div class="photo__bg">
              <div class="photo"></div>
              <v-avatar size="142" class="photo">
                <img :src="headImage" />
              </v-avatar>
            </div>
          </div>
        </div>
        <div class="member__space"></div>
        <!-- 資料輸入 -->
        <div class="personal__info">
          <div class="personal__item">
            <span class="item__title">姓名</span>
            <div class="item__input">
              <input
                type="text"
                v-model.trim="name"
                placeholder="請輸入姓名"
                maxlength="20"
              />
              <img
                class="input__error"
                v-if="!nameCheck"
                src="@/assets/icon_wrong.svg"
                alt
              />
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">性別</span>
            <div class="item__sex">
              <div
                class="sex-box male"
                :class="{ activeSex: activeSex == '男' }"
                @click="selectMale"
              >
                男生
              </div>
              <div
                class="sex-box female"
                :class="{ activeSex: activeSex == '女' }"
                @click="selectFemale"
              >
                女生
              </div>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">出生日期</span>

            <div class="item__input" @click.stop="dateModal = true">
              <span>{{ date }}</span>
              <v-dialog
                ref="dialog"
                v-model.trim="dateModal"
                :return-value.sync="date"
                width="290px"
                persistent
              >
                <v-date-picker
                  class="picker date-picker"
                  v-model.trim="date"
                  :first-day-of-week="0"
                  locale="zh-cn"
                  scrollable
                  header-color="rgba(217, 217, 217, 0.25)"
                  color="primary"
                  max="2099-12-31"
                  min="0676-01-01"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="error" @click="dateModal = false"
                    >取消</v-btn
                  >
                  <v-btn text color="error" @click="saveDate(date)">確認</v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">出生時間</span>
            <!-- - -->
            <div class="time-wrap">
              <div class="item__input" @click.stop="timeModal = true">
                <span>{{ time }}</span>
                <v-dialog
                  ref="dialog2"
                  v-model.trim="timeModal"
                  :return-value.sync="time"
                  width="290px"
                  persistent
                >
                  <v-time-picker
                    class="picker time-picker"
                    v-model.trim="time"
                    v-if="timeModal"
                    scrollable
                    ampm-in-title
                    header-color="rgba(217, 217, 217, 0.25)"
                    color="primary"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="error" @click="timeModal = false"
                      >取消</v-btn
                    >
                    <v-btn text color="error" @click="saveTime(time)"
                      >確認</v-btn
                    >
                  </v-time-picker>
                </v-dialog>
              </div>
              <label class="checkbox-wrap">
                我不知道
                <input type="checkbox" v-model.trim="IDontKnow" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title"
              >日光節約時間
              <img src="@/assets/日光節約icon.svg" />
            </span>
            <div class="item__sex">
              <div
                class="sex-box male"
                :class="{ activeSex: IsDaylight }"
                @click="
                  IsDaylight = true;
                  DaylightNotice = true;
                "
              >
                是
                <div class="daylight" v-if="DaylightNotice">
                  系統將自動<br />
                  以日光節約時間測算
                </div>
              </div>
              <div
                class="sex-box female"
                :class="{ activeSex: !IsDaylight }"
                @click="
                  IsDaylight = false;
                  DaylightNotice = false;
                "
              >
                否
              </div>
            </div>
          </div>
          <div class="personal__item">
            <span class="item__title">出生地點</span>

            <div class="item__input">
              <v-autocomplete
                class="select"
                :items="locationItems"
                v-model.trim="location"
                label="請選擇或輸入出生地點"
                background-color="transparent"
                solo
                hide-details
              ></v-autocomplete>
            </div>
          </div>

          <!-- 新增其他地點 -->
          <div class="personal__item" v-if="showOtherPlace">
            <span class="item__title otherPlace">出生國家</span>
            <div class="item__input">
              <v-autocomplete
                class="select"
                :items="$CountryCode"
                item-text="ChineseName"
                item-value="Code"
                v-model.trim="CountryCode"
                label="請選擇或輸入或輸入出生國家"
                background-color="transparent"
                solo
                hide-details
              ></v-autocomplete>
            </div>
          </div>
          <div class="personal__item" v-if="showOtherPlace">
            <span class="item__title otherPlace">出生州省</span>
            <div class="item__input">
              <input
                type="text"
                v-model.trim="State"
                value
                placeholder="請輸入州或省，例如 New York"
              />
            </div>
          </div>
          <div class="personal__item" v-if="showOtherPlace">
            <span class="item__title otherPlace">出生城市</span>
            <div class="item__input">
              <input
                type="text"
                v-model.trim="City"
                placeholder="請輸入城市，例如 Buffalo"
              />
              <img
                class="input__error"
                v-if="!cityCheck"
                src="@/assets/icon_wrong.svg"
                alt
              />
              <div class="notice_inputEnglish" v-if="showOtherPlace">
                州和城市輸入英文可提高準確度。
              </div>
            </div>
          </div>
          <!-- 新增其他地點end -->
          <!-- 信箱 開始 -->
          <!-- <div class="personal__item">
            <span class="item__title">信箱</span>
            <div class="item__input">
              <input
                type="text"
                v-model.trim="email"
                placeholder="請輸入信箱"
              />
              <img
                class="input__error"
                v-if="!email"
                src="@/assets/icon_wrong.svg"
                alt
              />
            </div>
          </div> -->
        </div>
        <!-- 資料輸入 End-->
        <div class="personal__btns">
          <div class="personal__cancel" @click="cancel">取消</div>
          <div
            class="personal__save"
            :class="{ notReady: !verify }"
            @click="update()"
          >
            儲存編輯
          </div>
        </div>
      </div>
    </div>
    <!-- <ConfirmEmail
      @done="confirmEmailHandler($event)"
      @resend="resendHandler()"
      v-if="openConfirmEmail"
    />
    <FixedPopup v-show="confirmResult_pass">
      <div class="confirmResultBox">
        <img src="@/assets/訂單流程/ok.svg" alt="" />
        <span>信箱已驗證成功，謝謝您！</span>
      </div>
    </FixedPopup>
    <FixedPopup v-show="confirmResult_fail">
      <div class="confirmResultBox">
        <img src="@/assets/訂單流程/fail.svg" alt="" />
        <span>驗證碼錯誤，請再輸入一次！</span>
      </div>
    </FixedPopup> -->
  </div>
</template>

<script>
// import ConfirmEmail from "@/components/訂單流程/ConfirmEmail";
// import FixedPopup from "@/components/Fixed_popup";

export default {
  // components: { ConfirmEmail, FixedPopup },
  data() {
    return {
      //信箱
      openConfirmEmail: false,
      confirmResult_pass: false,
      confirmResult_fail: false,
      //原始
      imageUrl: require("@/assets/cat.png"),
      DaylightNotice: false,
      activeSex: "男",
      date: "1990-05-30",
      dateModal: false,
      time: "12:00",
      timeModal: false,
      ID: null,
      name: null,
      location: "台北",
      locationItems: [
        "基隆",
        "台北",
        // "台北(Google)",
        "新北",
        "桃園",
        "新竹",
        "苗栗",
        "台中",
        "彰化",
        "南投",
        "雲林",
        "嘉義",
        "台南",
        "高雄",
        "屏東",
        "台東",
        "花蓮",
        "宜蘭",
        "澎湖",
        "金門",
        "馬祖",
        "香港",
        "澳門",
        "其他",
      ],
      checklist: [
        { text: "基隆", value: "Keelung" },
        { text: "台北", value: "Taipei" },
        // { text: "台北(Google)", value: "Taipei4Google" },
        { text: "新北", value: "New Taipei" },
        { text: "桃園", value: "Taoyuan" },
        { text: "新竹", value: "Hsinchu" },
        { text: "苗栗", value: "Miaoli" },
        { text: "台中", value: "Taichung" },
        { text: "彰化", value: "Changhua" },
        { text: "南投", value: "Nantou" },
        { text: "雲林", value: "Yunlin" },
        { text: "嘉義", value: "Chiayi" },
        { text: "台南", value: "Tainan" },
        { text: "高雄", value: "Kaohsiung" },
        { text: "屏東", value: "Pingtung" },
        { text: "台東", value: "Taitung" },
        { text: "花蓮", value: "Hualien" },
        { text: "宜蘭", value: "Yilan" },
        { text: "澎湖", value: "Penghu" },
        { text: "金門", value: "Kinmen" },
        { text: "馬祖", value: "Mazu" },
        { text: "香港", value: "Hongkong" },
        { text: "澳門", value: "Macao" },
        { text: "其他", value: "Other" },
      ],
      IDontKnow: false,
      nameCheck: true,
      countryCheck: true,
      cityCheck: true,
      fileNotApply: false,
      City: null,
      State: null,
      CountryCode: "",
      myDataBeEdit: null,
      showOtherPlace: false,
      passLocation: "Taipei",
      Gender: "M",
      Icon: require("@/assets/cat.png"),
      canClick: true,
      IsDaylight: false,
      activeByBtn: false,
      sourceCountry: null, //由舊資料取得
      email: "", //信箱
      sourceEmail: "", //舊信箱
    };
  },
  computed: {
    Country() {
      let Country = this.$CountryCode.find((item) => {
        return item.Code == this.CountryCode;
      });
      return Country ? Country.ChineseName : "台灣";
    },
    Zone() {
      let Country = this.$CountryCode.find((item) => {
        return item.Code == this.CountryCode;
      });
      return Country ? parseFloat(Country.Zone) : 8.0;
    },
    verify() {
      return !this.fileNotApply;
    },
    headImage() {
      return localStorage.imageUrl || this.Icon;
    },
  },
  watch: {
    location: function (value) {
      this.checklist.forEach((item) => {
        if (item.text === value) {
          this.passLocation = item.value;
        }
      });
      if (value === "其他") {
        this.showOtherPlace = true;
        this.fileNotApply = true;
      } else {
        this.showOtherPlace = false;
        if (this.nameCheck) {
          this.fileNotApply = false;
        }
        this.countryCheck = true;
        this.cityCheck = true;
        this.CountryCode = "TW";
        this.City = null;
      }
    },
    City: function (value) {
      if (this.location === "其他") {
        if (value === null || value === "") {
          this.cityCheck = false;
          this.fileNotApply = true;
        } else {
          this.cityCheck = true;
          if (this.nameCheck && this.countryCheck) {
            this.fileNotApply = false;
          }
        }
      }
    },
    name: function (value) {
      if (value === null || value === "") {
        this.nameCheck = false;
        this.fileNotApply = true;
      } else {
        this.nameCheck = true;
        if (this.countryCheck && this.cityCheck) {
          this.fileNotApply = false;
        }
      }
    },
    IDontKnow: function (value) {
      if (value === true) {
        this.time = "12:00";
      }
    },
  },
  mounted() {
    if (window.localStorage.getItem("editMe") !== null) {
      if (this.$store.state.myDate) {
        this.showData(this.$store.state.myDate);
      } else {
        this.$API.Get_MemberInfo().then(async (data) => {
          this.email = data.Data.Email;
          this.sourceEmail = data.Data.Email;

          window.localStorage["loginIndex"] = data.Data.MemberId;
          let self = this.$store.getters.SELF_OBJ;
          const res = await this.$API.Get_AstrolabeFile(self.AstrolabeFileId);
          let Mydata = res.Data;
          this.showData(Mydata);
          if (this.sourceCountry) {
            this.findCountryCode();
          }
        });
      }
    }
  },
  methods: {
    //處理data顯示，6/5新增
    showData(Mydata) {
      this.myDataBeEdit = Mydata;
      this.location = Mydata.BirthPlace ? Mydata.BirthPlace : Mydata.BirthCity;
      this.ID = this.myDataBeEdit.AstrolabeFileId;
      if (this.location === "其他") {
        this.showOtherPlace = true;
      }
      this.checklist.forEach((item, index) => {
        if (item.text === this.location) {
          this.passLocation = item.value;
        }
      });
      this.State = this.myDataBeEdit.BirthState;
      this.City = this.myDataBeEdit.BirthCity;
      this.CountryCode = this.myDataBeEdit.BirthCountryCode || "TW";
      this.sourceCountry = this.myDataBeEdit.BirthCountry;
      this.name = this.myDataBeEdit.Name;
      this.activeSex = this.myDataBeEdit.Gender;
      this.Icon = this.myDataBeEdit.ProfilePhotoSrc;
      this.IsDaylight = this.myDataBeEdit.IsDaylight;
      if (this.activeSex === "女") {
        this.Gender = "F";
      }
      this.time = this.myDataBeEdit.BirthTime;
      let BD = this.myDataBeEdit.BirthDay;
      let arr = BD.split("");
      arr[4] = "-";
      arr[7] = "-";
      BD = arr.join("");
      this.date = BD;
    },
    selectMale() {
      this.activeSex = "男";
      this.Gender = "M";
    },
    selectFemale() {
      this.activeSex = "女";
      this.Gender = "F";
    },
    cancel() {
      this.activeByBtn = true;
      window.localStorage.removeItem("editMe");
      this.$router.push({ path: "/member" });
    },
    next() {
      this.update();
      // if (this.email !== this.sourceEmail) {
      //   //需驗證信箱
      //   this.open_confirmEmail();
      // } else {
      //   this.update();
      // }
    },
    //信箱相關
    // open_confirmEmail() {
    //   this.openConfirmEmail = true;
    //   this.$API.POST_sendConfirmEmail({
    //     Email: this.email,
    //   });
    // },
    // resendHandler() {
    //   this.$API.POST_sendConfirmEmail({
    //     Email: this.email,
    //   });
    // },
    // confirmEmailHandler($event) {
    //   this.openConfirmEmail = false;
    //   this.$API
    //     .PUT_sendConfirmEmail({
    //       Email: this.email,
    //       Code: $event,
    //     })
    //     .then(({ data }) => {
    //       if (data.Data.Result) {
    //         //驗證成功
    //         let userData = this.$store.state.userData;
    //         userData.Email = this.email;
    //         this.$store.commit("set_userData", userData);
    //         this.confirmResult_pass = true;
    //         this.update();
    //         setTimeout(() => {
    //           this.update();
    //         }, 2000);
    //       } else {
    //         // 驗證失敗
    //         alert(data.Data.ErrMsg);
    //         this.confirmResult_fail = true;
    //         setTimeout(() => {
    //           this.confirmResult_fail = false;
    //         }, 2000);
    //       }
    //     });
    // },
    //更新會員資料
    update() {
      this.$noticePopup(() => {
        if (!this.verify) return;
        this.activeByBtn = true;
        this.updateReady().then((data) => {
          this.canClick = true;
          if (!data.Data.Result) {
            alert("輸入的國家或地區找不到，請重新輸入");
            return;
          }
          window.localStorage.setItem("friendState", "Edit");
          let direct = window.localStorage.getItem("toWhere");
          window.localStorage.removeItem("toWhere");
          if (direct) return this.$router.push({ path: direct });
          return this.$router.push({ path: "friendList" });
        });
      });
    },
    updateReady() {
      return new Promise((res, rej) => {
        if (this.location === "其他") {
          if (this.City === null || this.City === "") {
            this.cityCheck = false;
          } else {
            this.cityCheck = true;
          }
          if (this.Country === null || this.Country === "") {
            this.countryCheck = false;
          } else {
            this.countryCheck = true;
          }
        }
        if (this.verify) {
          if (this.canClick) {
            this.fileNotApply = true;
            setTimeout(() => {
              this.fileNotApply = false;
            }, 3000);
            this.canClick = false;

            if (this.IDontKnow) {
            }

            let BD = this.date.split("-");
            BD[0] = this.$formatNumber(BD[0]);
            BD = BD.join("/");
            const BirthTime = BD + " " + this.time;
            let data = {
              AstrolabeFileId: this.ID,
              Relationship: "R0",
              Name: this.name,
              Gender: this.Gender,
              BirthTime: this.time,
              BirthPlace: this.passLocation,
              BirthCountry: this.Country,
              BirthState: this.State ? this.State : null,
              BirthCity: this.City,
              BirthCountryCode: this.CountryCode,
              BirthTimeZone: this.Zone,
              IsDaylight: this.IsDaylight,
            };

            this.$store.commit("updateMyData", data);
            //拷貝，理由：後端參數前後不一
            let data2 = JSON.parse(JSON.stringify(data));
            data2.City = this.passLocation;
            data2.BirthTime = BirthTime;
            this.$API
              .Update_AstrolabeFile(data2)
              .then((data) => {
                if (!data) {
                  alert("輸入的國家或地區找不到，請重新輸入喔");
                }
                this.canClick = true;
                this.$store.state.needUpdate = true;
                res(data);
              })
              .catch((err) => {
                this.canClick = true;
              });
          }
        } else {
          this.fileNotApply = true;
        }
      });
    },
    saveDate(date) {
      this.$refs.dialog.save(date);
      // this.updateReady().then(() => {});
    },
    saveTime(time) {
      this.$refs.dialog2.save(time);
      // this.updateReady().then(() => {});
    },
    findCountryCode() {
      let CountryCode = this.$CountryCode.find((item) => {
        return item.ChineseName == this.sourceCountry;
      });
      this.CountryCode = CountryCode ? CountryCode.Code : "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/header.scss";
@import "@/scss/userFile.scss";
@import "@/scss/editFile.scss";

footer {
  margin-top: 0;
  @include pad {
    margin-top: 44px;
  }
}
</style>
